import React, { useContext, useEffect } from 'react'
import VendorContext from 'src/contexts/VendorContext'
import PageHeader from 'src/components/PageHeader';
import injectSheet from 'react-jss';
import PageLinkGrid from 'src/components/PageLinkGrid';
import PageLink from 'src/components/PageLink';
import SessionContext from 'src/contexts/SessionContext';
import urls from 'src/urls';
import BackLink from 'src/components/BackLink';

const styles = (theme: Theme) => ({

})

type P = {
  match: any
}

const VendorPage: StyledPFC<P, typeof styles> = (props) => {
  const vendorCtx = useContext(VendorContext)
  const session = useContext(SessionContext)
  const vendor = vendorCtx.vendors.filter(
    v => v._id === props.match.params.vendorId
  )[0]

  if (vendor === undefined) {
    return null
  }

  const showUploadProducts =
    session.canUploadProducts(props.match.params.vendorId) &&
    vendor.bulkImportJob !== undefined

  return (
    <>
      <BackLink url={urls.vendorHome} text='Mine leverandører' />
      <PageHeader>{vendor.title}</PageHeader>
      <PageLinkGrid>
        {showUploadProducts &&
          <PageLink
            icon='move_to_inbox'
            url={urls.vendorImportProducts(vendor._id)}
            text='Importer produkter'
          />
        }
        {session.canRegisterClients &&
          <PageLink
            icon='person_add'
            url={urls.vendorRegisterClient(vendor._id)}
            text='Register ny kundekonto'
          />
        }
      </PageLinkGrid>
    </>
  )
}

export default injectSheet(styles)(VendorPage)
import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames';

const styles = (theme: Theme) => ({
  header: {
    marginBottom: '1.6rem',
  },
  centered: {
    textAlign: 'center'
  }
})

type P = {
  centered?: boolean
}

const PageHeader: StyledPFC<P, typeof styles> = (props) => {
  const { classes, centered } = props
  const cls = classNames({
    [classes.header]: true,
    [classes.centered]: centered
  })
  return (
    <h1 className={cls}>
      {props.children}
    </h1>
  )
}

export default injectSheet(styles)(PageHeader)
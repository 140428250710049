import React, { useContext, useEffect } from 'react'
import { Route, Redirect, Link } from "react-router-dom"
import SessionContext from 'src/contexts/SessionContext'
import HomeAdmin from './HomeAdmin'
import Import from './Import'
import ProductImportStatus from '../ProductImportStatus';
import SendSignupEmail from './SendSignupEmail';
import VendorList from '../VendorList';
import VendorContext from 'src/contexts/VendorContext';
import urls from 'src/urls';

function AdminRoutes() {
  const session = useContext(SessionContext)
  const vendor = useContext(VendorContext)
  if (session.token === undefined || session.isSuperAdmin === undefined ||
    !session.isSuperAdmin) {
    return <Redirect to='/login' />
  }
  useEffect(() => {
    async function dataCheck() {
      await vendor.ensureFreshVendorData()
    }
    dataCheck()
  })

  return (
    <>
      <Route exact path={urls.adminSendSignupEmail} component={SendSignupEmail} />
      <Route exact path='/admin' component={HomeAdmin} />
    </>
  )
}

export default AdminRoutes
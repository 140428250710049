import React, { useContext } from 'react'
import injectStyles, { WithSheet } from 'react-jss'
import { Link } from 'react-router-dom'

import SessionContext from 'src/contexts/SessionContext'
import UserContext from 'src/contexts/UserContext'
import logo from 'src/media/mwlogo.png'
import Button from 'src/components/Button';
import MediaContext from 'src/contexts/MediaContext';


const styles = (theme: Theme) => ({
  header: {
    ...theme.rootComponents,
    backgroundColor: theme.bgColor,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottom: theme.lightBorder,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '7rem'
  },
  logoDesktop: {
    height: '6rem'
  },
  logoMobile: {
    height: '4rem'
  }
})

type LogoutProps = {
  actions: Function[]
}

const LogoutButton = (props: LogoutProps) =>
  <button onClick={() => props.actions.forEach(a => a())}>
    Logout
  </button>

function Header(props: WithSheet<typeof styles>) {
  const session = useContext(SessionContext)
  const media = useContext(MediaContext)
  const { classes } = props
  const logoCls = media.screenSize === 'desktop' ?
    classes.logoDesktop : classes.logoMobile
  return (
    <header className={props.classes.header}>
      <Link to='/'>
        <img
          className={logoCls}
          src={logo}
          alt="Marketwell Logo"
        />
      </Link>
      {session.token &&
        <Button
          noMargin={true}
          type='primary'
          onClick={session.logout}>
          Logg ut
        </Button>
      }
    </header>
  )
}


export default injectStyles(styles)(Header)
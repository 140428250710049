import React, { ReactNode } from 'react'
import injectSheet, { WithSheet } from 'react-jss'
import classNames from 'classnames'

const styles = (theme: Theme) => ({
  button: {
    padding: '1rem',
    fontSize: '1.4rem',
    borderRadius: '5px',
    fontWeight: 300,
    transition: 'background, border-color',
    transitionDuration: 200,
    transitionTimingFunction: 'ease-in-out',
  },
  primary: {
    background: theme.primaryColor,
    color: theme.primaryTxtColor,
    borderColor: theme.primaryColor,
    '&:hover': {
      background: theme.primaryColorDark,
      cursor: 'pointer'
    },
    '&:disabled': {
      background: theme.primaryColorLight,
      borderColor: theme.primaryColorLight,
      '&:hover': {
        cursor: 'not-allowed'
      }
    }
  },
  topMargin: {
    marginTop: '1.6rem'
  },
  fullWidth: {
    width: '100%',
  }
})

type Props = {
  type: 'primary' | 'secondary'
  onClick: () => void,
  children?: ReactNode,
  fullWidth?: boolean,
  disabled?: boolean,
  noMargin?: boolean
}

const Button = (props: Props & WithSheet<typeof styles>) => {
  const { classes, noMargin } = props
  const { button, primary, fullWidth, topMargin } = classes

  const btnClasses = classNames({
    [button]: true,
    [primary]: props.type === 'primary',
    [fullWidth]: props.fullWidth,
    [topMargin]: noMargin === undefined || noMargin === false
  })
  return (
    <button
      disabled={!!props.disabled}
      onClick={props.onClick}
      className={btnClasses}>
      {props.children}
    </button>
  )
}

export default injectSheet(styles)(Button)
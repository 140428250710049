import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import VendorContext from 'src/contexts/VendorContext'
import urls from 'src/urls';
import injectSheet from 'react-jss';
import PageLinkGrid from 'src/components/PageLinkGrid';
import PageHeader from 'src/components/PageHeader';

const styles = (theme: Theme) => ({
  vendorPageLink: theme.pageLink,
  logo: {
    height: '10rem'
  },
  title: {
    color: theme.txtColor
  }
})

type P = {
  urlBuilder: Function
}

const VendorList: StyledPFC<P, typeof styles> = (props) => {
  const vendor = useContext(VendorContext)
  const { classes } = props

  return (
    <>
      <PageHeader>Mine leverandører</PageHeader>
      <PageLinkGrid>
        {vendor.vendors.map((v: Vendor, index) =>
          <Link
            key={index}
            to={urls.vendorPage(v._id)}
            className={classes.vendorPageLink}>
            <img
              src={v.logo}
              alt=""
              className={classes.logo}
            />
            <p className={classes.title}>{v.title}</p>
          </Link>
        )}
      </PageLinkGrid>
    </>
  )
}

export default injectSheet(styles)(VendorList)
import React from 'react'


const mq = window.matchMedia('(max-width: 600px)')

const getWidth = (mq: MediaQueryList) =>
  mq.matches ? 'mobile' : 'desktop'


type ScreenSize = 'mobile' | 'desktop'

const initialState = {
  screenSize: 'desktop' as ScreenSize
}

const MediaContext = React.createContext(initialState)

export default MediaContext

type State = typeof initialState;

export class MediaProvider extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      screenSize: getWidth(mq)
    }
  }

  componentWillMount() {
    mq.addListener(this.widthChange.bind(this))
  }

  componentWillUnmount() {
    mq.removeListener(this.widthChange.bind(this))
  }

  widthChange(mq: any) {
    this.setState({ screenSize: getWidth(mq) })
  }

  render() {
    return (
      <MediaContext.Provider value={this.state}>
        {this.props.children}
      </MediaContext.Provider>
    )
  }
}



import React, { useContext, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import SessionContext from 'src/contexts/SessionContext'
import injectStyles, { WithSheet } from 'react-jss'
import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import TextLink from 'src/components/TextLink';
import urls from 'src/urls';

const styles = (theme: Theme) => ({
  container: theme.centerPageContainer,
  content: {
    maxWidth: '28rem'
  },
  form: {
    marginTop: '1rem'
  },
  error: {
    color: theme.errorColor
  }
})


const Login = (props: WithSheet<typeof styles>) => {
  const ctx = useContext(SessionContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { classes } = props
  if (ctx.token) {
    const redirectPath = ctx.isSuperAdmin ? '/admin' : '/vendor'
    return <Redirect to={redirectPath} />
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await ctx.login(email, password)
    }
    catch (err) {
      setError(err.message)
    }
  }

  const updateVal = (val: string, updateFunc: Function) => {
    updateFunc(val)
    setError('')
  }
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1>Logg inn</h1>
        <form
          className={classes.form}
          autoComplete='off'
          onSubmit={handleSubmit}>
          <TextInput
            id='email'
            type='text'
            value={email}
            label='Epost'
            onChange={(val: string) => updateVal(val, setEmail)}
          />
          <TextInput
            id='password'
            type='password'
            value={password}
            label='Passord'
            onChange={(val: string) => updateVal(val, setPassword)}
          />
          {error &&
            <p className={classes.error}>{error}</p>
          }
          <Button
            type='primary'
            onClick={() => null}>
            Logg inn
          </Button>
          <TextLink to={urls.forgotPassword}>
            Glemt passord?
          </TextLink>
        </form>
      </div>
    </div >
  )
}

export default injectStyles(styles)(Login)
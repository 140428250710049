import React, { useContext, useEffect } from 'react'
import { Route, Redirect, Link } from "react-router-dom"
import SessionContext from 'src/contexts/SessionContext'
import VendorList from '../VendorList'
import VendorPage from './VendorPage';
import VendorContext from 'src/contexts/VendorContext';
import LoadingSpinner from 'src/components/LoadingSpinner';
import ImportProducts from '../ImportProducts';
import ProductImportStatus from '../ProductImportStatus';
import RegisterClient from '../RegisterClient';

function VendorRoutes() {
  const session = useContext(SessionContext)
  const vendor = useContext(VendorContext)
  if (session.token === undefined) {
    return <Redirect to='/login' />
  }
  useEffect(() => {
    async function dataCheck() {
      await vendor.ensureFreshVendorData()
    }
    dataCheck()
  })
  return (
    <>
      <Route exact path='/vendor/:vendorId/importProducts' component={ImportProducts} />
      <Route exact path='/vendor/:vendorId/productImport/:channel' component={ProductImportStatus} />
      <Route exact path='/vendor/:vendorId/registerClient' component={RegisterClient} />
      <Route exact path='/vendor/:vendorId' component={VendorPage} />
      <Route exact path='/vendor' component={VendorList} />
    </>
  )
}

export default VendorRoutes
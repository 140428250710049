import React, { useContext, useEffect, useState } from 'react'
import injectSheet from 'react-jss';
import UserContext from 'src/contexts/UserContext';
import LoadingSpinner from 'src/components/LoadingSpinner';
import Button from 'src/components/Button';

const styles = (theme: Theme) => ({
  pageHeader: {
    marginTop: '1.6rem'
  },
  userList: {
    width: '100%',
    marginTop: '1.6rem'
  },
  userEntry: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.8rem 0',
    borderTop: theme.lightBorder,
    '&:last-child': {
      borderBottom: theme.lightBorder,
    }
  },
  username: {
    flex: 1
  },
  sendButton: {
    width: '6rem'
  }
})

const SendSignupEmail: StyledFC<typeof styles> = (props) => {
  const userCtx = useContext(UserContext)
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    async function ensureFresh() {
      await userCtx.ensureFreshNewUsers()
    }
    if (firstRender) {
      ensureFresh()
      setFirstRender(false)
    }
  })

  if (userCtx.fetchingNewUsers) {
    return <LoadingSpinner />
  }

  const { newUsers } = userCtx
  const { classes } = props
  return (
    <>
      <h1 className={classes.pageHeader}>Nye brukere</h1>
      <ul className={classes.userList}>
        {newUsers.map((user, index) => (
          <li key={index} className={classes.userEntry}>
            <div className={classes.username}>{user.email}</div>
            <div className={classes.sendButton}>
              {userCtx.sendingEmail === user ?
                <Button
                  fullWidth={true}
                  disabled={true}
                  type='primary'
                  onClick={() => null}>
                  <LoadingSpinner small={true} />
                </Button> :
                <Button
                  noMargin={true}
                  fullWidth={true}
                  type='primary'
                  onClick={() => userCtx.sendSignupEmail(user)}>
                  Send
                </Button>
              }
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default injectSheet(styles)(SendSignupEmail)


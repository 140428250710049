export default {
  login: '/login',
  adminHome: '/admin',
  adminSendSignupEmail: '/admin/sendSignupEmail',
  vendorHome: '/vendor',
  vendorPage: (id: string) => `/vendor/${id}`,
  vendorImportProducts: (id: string) => `/vendor/${id}/importProducts`,
  vendorProductImportProgress: (id: string, channel: string) =>
    `/vendor/${id}/productImport/${channel}`,
  vendorRegisterClient: (id: string) => `/vendor/${id}/registerClient`,
  resetPassword: '/resetPassword/:token',
  accountConfirmed: '/accountConfirmed/:token',
  forgotPassword: '/forgotPassword',
}
import React from 'react'
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';

const styles = (theme: Theme) => ({
  link: {
    color: theme.primaryColorLight,
    display: 'block',
    marginTop: '1.6rem'
  }
})

type P = {
  to: string
}

const TextLink: StyledPFC<P, typeof styles> = (props) => {

  return (
    <Link
      to={props.to}
      className={props.classes.link}>
      {props.children}
    </Link>
  )
}

export default injectSheet(styles)(TextLink)
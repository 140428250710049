import React from 'react'
import injectSheet from 'react-jss';
import classNames from 'classnames';

const styles = (theme: Theme) => ({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerFullscreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  spinner: {
    border: `solid 3px ${theme.primaryColorVeryLight}`,
    borderTopColor: theme.primaryColor,
    borderRadius: '50%',
    opacity: 1,
    animation: 'spin 1s ease-in-out infinite'
  },
  largeSpinner: {
    width: '50px',
    height: '50px',
  },
  smallSpinner: {
    width: '20px',
    height: '20px',
  }
})

type P = {
  small?: boolean
}

const LoadingSpinner: StyledPFC<P, typeof styles> = (props) => {
  const { classes, small } = props
  const containerCls = classNames({
    [classes.container]: true,
    [classes.containerFullscreen]: small === undefined || small === false
  })
  const spinnerCls = classNames({
    [classes.spinner]: true,
    [classes.largeSpinner]: small === undefined || small === false,
    [classes.smallSpinner]: small
  })
  return (
    <div className={containerCls}>
      <div className={spinnerCls} />
    </div>
  )
}


export default injectSheet(styles)(LoadingSpinner)
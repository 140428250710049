import React, { useState, useContext } from 'react'
import injectSheet from 'react-jss';
import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import PageHeader from 'src/components/PageHeader';
import { validatePassword } from 'src/validators';
import urls from 'src/urls';
import TextLink from 'src/components/TextLink';
import SessionContext from 'src/contexts/SessionContext';
import { RouteComponentProps } from 'react-router';
import { parseJwt } from 'src/utils';

const styles = (theme: Theme) => ({
  formHeader: {
    marginTop: '1.6rem'
  }
})

const formValid = (password: string, confirm: string) =>
  validatePassword(password).length === 0 &&
  validatePassword(confirm).length === 0 &&
  password === confirm

type P = {
  match: any
}

const SetPassword: StyledPFC<P & RouteComponentProps, typeof styles> = (props) => {
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [passwordUpdated, setPasswordUpdated] = useState(false)
  const session = useContext(SessionContext)
  const { classes, match } = props
  const { token } = match.params
  const tokenData = parseJwt(token)
  if (token === undefined || token === '') {
    return <div>No token!</div>
  }
  const resetRoute = props.location.pathname.startsWith('/resetPassword')
  return (
    <div>
      <div>
        {passwordUpdated ?
          <>
            <PageHeader centered={false}>Passord oppdatert</PageHeader>
            <p>
              Passordet ditt er nå oppdatert.
            </p>
            {tokenData.backofficeUser &&
              <TextLink to={urls.login}>Logg inn</TextLink>
            }
          </> :
          <>
            <PageHeader centered={false}>
              {resetRoute ? 'Nullstill passord' : 'Konto bekreftet'}
            </PageHeader>
            {resetRoute ?
              <>
                <p>Skriv inn ditt nye passord.</p>
                <p>Passordet må ha en lengde på minst 8 tegn.</p>
              </> :
              <>
                <p>Gratulerer, din konto er nå bekreftet!</p>
                <p>
                  For å få logget inn, må du først lage deg et passord.
                  </p>
                <p>Passordet må bestå av minst 8 tegn.</p>
              </>
            }
            <h2 className={classes.formHeader}>Sett passord</h2>
            <TextInput
              type='password'
              label='Passord'
              value={password}
              id='password'
              onChange={setPassword}
            />
            <TextInput
              type='password'
              label='Bekreft passord'
              value={confirm}
              id='confirm'
              onChange={setConfirm}
            />
            <Button
              type='primary'
              disabled={!formValid(password, confirm)}
              onClick={async () => {
                await session.resetPassword(password, match.params.token)
                setPasswordUpdated(true)
                setConfirm('')
                setPassword('')
              }}>
              Send
                </Button>

          </>
        }
      </div>
    </div>
  )
}

export default injectSheet(styles)(SetPassword)
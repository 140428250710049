import React from 'react'
import injectSheet, { WithSheet } from 'react-jss'
import classNames from 'classnames';

const styles = (theme: Theme) => ({
  sm: {
    fontSize: '24px'
  },
  md: {
    fontSize: '48px'
  },
  lg: {
    fontSize: '72px'
  },
  primary: {
    color: theme.primaryColor
  }
})

type Props = {
  icon: string,
  size: 'sm' | 'md' | 'lg',
  color?: string
}

const Icon: StyledPFC<Props, typeof styles> = (props) => {
  const { classes, color } = props
  const cls = classNames({
    'material-icons': true,
    [classes.sm]: props.size === 'sm',
    [classes.md]: props.size === 'md',
    [classes.lg]: props.size === 'lg',
    [classes.primary]: props.color === undefined
  })
  return (
    <i className={cls}>
      {props.icon}
    </i>
  )
}

export default injectSheet(styles)(Icon)
import React from 'react';
import injectStyles, { ThemeProvider, WithSheet } from 'react-jss'
import { BrowserRouter as Router, Route } from "react-router-dom"

import { SessionProvider } from './contexts/SessionContext'
import Index from './views/Index'
import Login from './views/Login'
import Header from './views/Header';
import Footer from './views/Footer';

import urls from 'src/urls'
import theme from './theme'
import AdminRoutes from './views/admin/AdminRoutes'
import { UserProvider } from './contexts/UserContext'
import { VendorProvider } from './contexts/VendorContext'
import { MediaProvider } from './contexts/MediaContext'
import SetPassword from './views/SetPassword';
import ForgotPassword from './views/ForgotPassword';
import VendorRoutes from './views/vendor/VendorRoutes';

const styles = (theme: Theme) => ({
  // '@global': {
  //   html: {
  //     fontSize: '62.5%',
  //     minHeight: '100%',
  //     height: '100%'
  //   },
  //   body: {
  //     fontFamily: 'Roboto',
  //     fontWeight: 300,
  //     backgroundColor: theme.bgColor,
  //     color: theme.txtColor,
  //     fontSize: '16px',
  //     minHeight: '100%',
  //     height: '100%'
  //   },
  //   h1: {
  //     fontWeight: 300,
  //   },
  //   h2: {
  //     fontWeight: 300,
  //   },
  //   h3: {
  //     fontWeight: 300,
  //   },
  //   h4: {
  //     fontWeight: 300,
  //   },
  //   p: {
  //     fontWeight: 300,
  //     lineHeight: 1.4,
  //     marginTop: '0.8rem'
  //   },
  //   a: {
  //     textDecoration: 'none',
  //     color: theme.primaryColorLight,
  //     '&:visited': {
  //       color: theme.primaryColor
  //     },
  //     '&:hover': {
  //       textDecoration: 'underline',
  //       color: theme.primaryColorDark
  //     }
  //   },
  //   '#root': {
  //     minHeight: '100%',
  //     height: '100%',
  //     display: 'flex',
  //     flexDirection: 'row'
  //   },
  //   '*': {
  //     boxSizing: 'border-box',
  //     margin: 0,
  //     padding: 0,
  //     outline: 0
  //   }
  // },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '960px',
    margin: '0 auto'
  },
  main: {
    backgroundColor: '#fff',
    position: 'relative',
    flex: 1,
    paddingTop: '1.6rem',
    paddingBottom: '1.6rem',
    ...theme.rootComponents
  },
})

const App = (props: WithSheet<typeof styles>) => {
  return (
    <div className={props.classes.app}>
      <Header />
      <main className={props.classes.main}>
        <Route exact path='/' component={Index} />
        <Route path={urls.login} component={Login} />
        <Route path={urls.resetPassword} component={SetPassword} />
        <Route path={urls.accountConfirmed} component={SetPassword} />
        <Route path={urls.forgotPassword} component={ForgotPassword} />
        <Route path={urls.vendorHome} component={VendorRoutes} />
        <Route path={urls.adminHome} component={AdminRoutes} />
      </main>
      <Footer />
    </div>
  )
}

const StyledApp = injectStyles(styles)(App)

class AppContainer extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <SessionProvider>
            <UserProvider>
              <VendorProvider>
                <MediaProvider>
                  <StyledApp />
                </MediaProvider>
              </VendorProvider>
            </UserProvider>
          </SessionProvider>
        </Router>
      </ThemeProvider>
    )
  }
}

export default AppContainer;

const hostname = process.env.REACT_APP_HOSTNAME
const httpProtocol = process.env.REACT_APP_HTTP_PROTOCOL
const wsProtocol = process.env.REACT_APP_SOCKET_PROTOCOL

if (hostname === undefined) {
  throw new Error('process.env.REACT_APP_HOSTNAME not set!')
}
if (httpProtocol === undefined) {
  throw new Error('process.env.REACT_APP_HTTP_PROTOCOL not set!')
}

if (wsProtocol === undefined) {
  throw new Error('process.env.REACT_APP_SOCKET_PROTOCOL not set!')
}

export const baseUrl = `${httpProtocol}://${hostname}`

export const baseApiUrl = `${baseUrl}/api/`
export const tokenUrl = `${baseUrl}/auth/getToken`
export const forgotPasswordUrl = `${baseUrl}/auth/forgotPassword`
export const resetPasswordUrl = `${baseUrl}/auth/resetPassword`

export const socketUrl = `${wsProtocol}://${hostname}/socket`
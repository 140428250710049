import React, { useState, useContext } from 'react'
import injectSheet from 'react-jss';
import PageHeader from 'src/components/PageHeader';
import TextInput from 'src/components/TextInput';
import VendorContext from 'src/contexts/VendorContext';
import Button from 'src/components/Button';
import { validateStringNotEmpty, validateEmail } from 'src/validators';
import BackLink from 'src/components/BackLink';
import urls from 'src/urls';
import LoadingSpinner from 'src/components/LoadingSpinner';

const styles = (theme: Theme) => ({
  error: {
    color: theme.errorColor
  }
})

type P = {
  match: any
}

type FormPayload = {
  title: string,
  phone: string,
  vendorId: string,
  email: string,
  firstName: string,
  lastName: string,
}

const validForm = (
  { title, phone, vendorId, email, firstName, lastName }: FormPayload
) => {
  return validateStringNotEmpty(title).length === 0 &&
    validateStringNotEmpty(phone).length === 0 &&
    validateStringNotEmpty(vendorId).length === 0 &&
    validateEmail(email).length === 0 &&
    validateStringNotEmpty(firstName).length === 0 &&
    validateStringNotEmpty(lastName).length === 0
}

const RegisterClient: StyledPFC<P, typeof styles> = (props) => {
  const [title, setTitle] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const vendorCtx = useContext(VendorContext)
  const vendorId = props.match.params.vendorId
  const vendor = vendorCtx.vendors.filter(v => v._id === vendorId)[0]
  if (vendor === undefined) {
    return null
  }
  if (vendorCtx.registeringNewClient) {
    return <LoadingSpinner />
  }
  const updateForm = (value: string, updateFieldFunc: Function) => {
    setError('')
    updateFieldFunc(value)
  }

  const handleSubmit = async () => {
    setError('')
    const success = await vendorCtx.registerClient({
      title, phone, vendorId, email, firstName, lastName
    })
    console.log('RES', success)
    if (success === true) {
      setTitle('')
      setEmail('')
      setPhone('')
      setFirstName('')
      setLastName('')
    }
    else if (success.message === 'User already exists.') {
      setError('Epostadressen er allerede i bruk.')
    }
  }

  return (
    <>
      <BackLink url={urls.vendorPage(vendorId)} text={vendor.title} />
      <PageHeader>Registrer ny kundekonto</PageHeader>
      <p>
        Merk at personen som eier e-postadressen du registrerer kunden på,
        blir satt som kundens administrator.
      </p>
      {error &&
        <p className={props.classes.error}>{error}</p>
      }
      <TextInput
        value={title}
        onChange={(val: string) => updateForm(val, setTitle)}
        label='Navn på butikk/salong'
        id='title'
        type='text'
      />
      <TextInput
        value={firstName}
        onChange={(val: string) => updateForm(val, setFirstName)}
        label='Fornavn'
        id='firstName'
        type='text'
      />
      <TextInput
        value={lastName}
        onChange={(val: string) => updateForm(val, setLastName)}
        label='Etternavn'
        id='lastName'
        type='text'
      />
      <TextInput
        value={email}
        onChange={(val: string) => updateForm(val, setEmail)}
        label='Epost'
        id='email'
        type='text'
      />
      <TextInput
        value={phone}
        onChange={(val: string) => updateForm(val, setPhone)}
        label='Tlf'
        id='phone'
        type='text'
      />
      <Button
        disabled={!validForm({
          vendorId, title, email, phone, firstName, lastName
        })}
        onClick={handleSubmit}
        type='primary'>
        Registrer
      </Button>
    </>
  )
}

export default injectSheet(styles)(RegisterClient)
const variables = {
  rootComponents: {
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  centerPageContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  bgColor: '#fff',
  txtColor: '#333',
  lightBorder: '1px solid #DEDEDE',
  primaryColorVeryLight: '#cdf3f0',
  primaryColorLight: '#99bfc3',
  primaryColor: '#008999',
  primaryColorDark: '#006979',
  primaryTxtColor: '#FFF',
  errorColor: '#F00000',
  smallShadow: '1px 1px 4px 0px rgb(204, 202, 204)',
  largeShadow: '2px 2px 16px 0px rgb(204, 202, 204)',
  largeShadowHover: '7px 7px 24px 0px rgba(145,143,145,1)'
}

const pageLink = {
  padding: '2rem 2rem',
  borderRadius: '5px',
  border: '1px solid #CDCDCD',
  textAlign: 'center',
  transition: 'box-shadow 200ms ease-in-out',
  display: 'block',
  width: '100%',
  boxShadow: variables.smallShadow,
  color: variables.primaryColor
}

const theme = {
  ...variables,
  pageLink
}
declare global {
  type Theme = typeof theme
}

export default theme
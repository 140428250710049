import React from 'react'
import injectSheet, { WithSheet } from 'react-jss'

const styles = (theme: Theme) => ({
  wrapper: {
    marginTop: '1.6rem'
  },
  label: {

  },
  input: {
    display: 'block',
    lineHeight: 2,
    fontSize: '1.4rem',
    fontWeight: 300,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: '1px solid #DEDEDE',
    borderColor: '#DEDEDE',
    width: '20rem',
    transition: 'border-color 200ms ease-in-out',
    '&:focus': {
      borderColor: theme.primaryColor,
    }
  }
})

type Props = {
  value: string,
  onChange: (newVal: string) => void,
  label: string,
  id: string,
  type: 'text' | 'password'
}

const TextInput = (props: Props & WithSheet<typeof styles>) => {
  const { classes } = props
  return (
    <div className={classes.wrapper}>
      <label
        className={classes.label}
        htmlFor={props.id}>
        {props.label}
        <input
          className={props.classes.input}
          value={props.value}
          type={props.type}
          onChange={(e) => props.onChange(e.target.value)}
          id={props.id}
        />
      </label>
    </div>
  )
}

export default injectSheet(styles)(TextInput)
import React from 'react'
import injectStyles, { StyledComponentProps } from 'react-jss';

const styles = (theme: Theme) => ({
  footer: {
    backgroundColor: theme.bgColor,
    borderTop: theme.lightBorder,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    ...theme.rootComponents
  },
})

function Footer(props: StyledComponentProps) {
  const classes = props.classes || {}
  return (
    <footer className={classes.footer}>

    </footer>
  )
}

export default injectStyles(styles)(Footer)
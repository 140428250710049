import * as React from 'react'
import injectStyles, { WithSheet } from 'react-jss'
import { Socket } from 'phoenix'
import { socketUrl } from 'src/config';

const socket = new Socket(socketUrl)

const styles = (theme: Theme) => ({
  main: {
    marginBottom: '1rem'
  },
  resultContainer: {
    marginBottom: '3rem'
  },
  productEntry: {
    padding: '0.5rem 0',
    borderBottom: '1px solid #cdcdcd',
    '&:first-child': {
      bordertop: '1px solid #cdcdcd',
    }
  },
  error: {
    color: '#FF0000'
  },
  showToggle: {
    color: '#0000FF',
    fontSize: '2rem',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
})

interface Product {
  title: string,
  brandName: string,
  vendorProductId: string,
  [index: string]: any
}

type P = {
  match: any
}

type Props = P & WithSheet<typeof styles>

interface InvalidProduct {
  product: Product,
  errors: { [index: string]: string }
}

interface InvalidProductsPayload {
  invalid: InvalidProduct[]
}

interface Report {
  imported: Product[]
}

type State = {
  status: string,
  invalidProducts: InvalidProduct[],
  report?: any,
  imported: any[],
  showValid: boolean,
  showInvalid: boolean,
  showImageUploadErrors: boolean,
  imageUploadErrors: ImageUploadError[]
}

interface ImageUploadError {
  product: Product,
  errors: string[]
}

class ProductImportStatus extends React.Component<Props, State> {
  state: State = {
    status: 'Waiting on status update from import...',
    invalidProducts: [],
    imported: [],
    showValid: false,
    showInvalid: false,
    showImageUploadErrors: false,
    imageUploadErrors: []
  }
  componentWillMount() {

    socket.connect()
    console.log('CHANNEL IS', this.props.match.params.channel)
    const channel = socket.channel(this.props.match.params.channel, {})

    channel.join()
      .receive("ok", (resp: any) => { console.log("Joined successfully", resp) })
      .receive("error", (resp: any) => { console.log("Unable to join", resp) })

    channel.on('status', (payload: any) => {
      this.handleStatusUpdate(payload)
    })

    channel.on('report', (payload: Report) => {
      this.handleReport(payload)
    })

    channel.on('invalidProducts', (payload: InvalidProductsPayload) => {
      this.handleInvalidProducts(payload)
    })

    channel.on('criticalError', (payload: any) => {
      console.log('CRITICAL ERROR', payload.msg)
    })

    channel.on('missingImage', (payload: any) => {
      console.log('MISSING IMAGE', payload.image)
    })

    channel.on('imageUploadError', (payload: ImageUploadError) => {
      this.handleImageUploadError(payload)
    })
  }
  handleStatusUpdate(payload: any) {
    this.setState({ status: payload.msg })
  }
  handleInvalidProducts(payload: InvalidProductsPayload) {
    this.setState({ invalidProducts: payload.invalid })
  }
  handleImageUploadError(payload: ImageUploadError) {
    this.setState(
      { imageUploadErrors: this.state.imageUploadErrors.concat([payload]) }
    )
  }
  handleReport(report: Report) {
    this.setState({ imported: report.imported })
  }
  toggleShowValid() {
    this.setState({ showValid: !this.state.showValid })
  }
  toggleShowInvalid() {
    this.setState({ showInvalid: !this.state.showInvalid })
  }
  toggleShowImageUploadErrors() {
    this.setState({ showImageUploadErrors: !this.state.showImageUploadErrors })
  }
  render() {
    const { classes } = this.props
    const {
      status, invalidProducts, imported,
      showValid, showInvalid,
      showImageUploadErrors, imageUploadErrors
    } = this.state
    return (
      <div>
        <h1>Importing products</h1>
        <h3>Status: {status}</h3>
        <h2>Import summary:</h2>
        <h3>
          Invalid products ({invalidProducts.length})&nbsp;
            <span className={classes.showToggle}
            onClick={this.toggleShowInvalid.bind(this)}>
            ({showInvalid ? 'Hide' : 'Show'})
            </span>
        </h3>
        {showInvalid &&
          <div className={classes.resultContainer}>
            {invalidProducts.map((entry, index) => {
              const { product, errors } = entry
              const errorTexts = Object.keys(errors).map(field => (
                <div key={field}>
                  {field}:&nbsp;
                    <span className={classes.error}>
                    {product[field]} - {errors[field]}
                  </span>
                </div>
              ))
              return (
                <div key={index} className={classes.productEntry}>
                  <div>{product.title} - {product.vendorProductId}</div>
                  {errorTexts}
                </div>
              )
            })}
            <p onClick={this.toggleShowInvalid.bind(this)}
              className={classes.showToggle}>
              Close
              </p>
          </div>
        }
        <h3>
          Imported products ({imported.length})&nbsp;
            <span
            className={classes.showToggle}
            onClick={this.toggleShowValid.bind(this)}>
            ({showValid ? 'Hide' : 'Show'})
            </span>
        </h3>
        {showValid &&
          <div className={classes.resultContainer}>
            {imported.map((product, index) => {
              return (
                <div key={index} className={classes.productEntry}>
                  Navn: {product.title} - ID: {product.vendorProductId}
                </div>
              )
            })}
            <p onClick={this.toggleShowValid.bind(this)}
              className={classes.showToggle}>
              Close
              </p>
          </div>
        }
        <h3>
          Failed image uploads ({imageUploadErrors.length})&nbsp;
            <span
            className={classes.showToggle}
            onClick={this.toggleShowImageUploadErrors.bind(this)}>
            ({showImageUploadErrors ? 'Hide' : 'Show'})
            </span>
        </h3>
        {showImageUploadErrors &&
          <div className={classes.resultContainer}>
            {imageUploadErrors.map((entry: ImageUploadError, index: number) => {
              const { product, errors } = entry
              return (
                <div key={index} className={classes.productEntry}>
                  <div>{product.title} - {product.vendorProductId}</div>
                  {errors.map((error, index) =>
                    <p className={classes.error} key={index}>{error}</p>
                  )}
                </div>
              )
            })}
            <p onClick={this.toggleShowImageUploadErrors.bind(this)}
              className={classes.showToggle}>
              Close
            </p>
          </div>
        }
      </div>
    )
  }
}

export default injectStyles(styles)(ProductImportStatus)
import React from 'react'
import { Link } from 'react-router-dom';
import Icon from './Icon';
import injectSheet from 'react-jss';

const styles = (theme: Theme) => ({
  backLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1.25rem',
    '&:hover': {
      textDecoration: 'none'
    }
  }
})

type P = {
  url: string,
  text: string
}

const BackLink: StyledPFC<P, typeof styles> = (props) => {
  return (
    <Link
      to={props.url}
      className={props.classes.backLink}>
      <Icon icon='keyboard_arrow_left' size='md' />
      {props.text}
    </Link>
  )
}

export default injectSheet(styles)(BackLink)
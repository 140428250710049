const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const validateStringNotEmpty = (str: string, errors: string[] = []) => {
  if (str === undefined || str === null || str === '') {
    errors.push('Cannot be empty.')
  }
  return errors
}

export const validateEmail = (email: string, errors: string[] = []) => {
  if (!emailRegex.test(email)) {
    errors.push('Not a valid email.')
  }
  return errors
}

export const validatePassword = (password: string, errors: string[] = []) => {
  if (password.length < 8) {
    errors.push('Must be at least 8 characters.')
  }
  return errors
}
import React, { useState, useContext } from 'react'
import injectSheet from 'react-jss';
import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import PageHeader from 'src/components/PageHeader';
import { validateEmail } from 'src/validators';
import SessionContext from 'src/contexts/SessionContext';

const styles = (theme: Theme) => ({
  formHeader: {
    marginTop: '1.6rem'
  }
})

const ForgotPassword: StyledFC<typeof styles> = (props) => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const session = useContext(SessionContext)
  const { classes } = props
  const validEmail = validateEmail(email)
  if (emailSent) {
    return (
      <>
        <PageHeader>E-post sendt</PageHeader>
        <p>
          Om e-postadressen du tastet inn er knyttet til en Marketwell-konto,
          vil du straks motta en e-post fra oss med videre instruksjoner.
        </p>
      </>
    )
  }
  return (
    <>
      <PageHeader>Nullstill passord</PageHeader>
      <p>Skriv inn e-postadressen assosiert med din Marketwell-konto.</p>
      <p>Du vil deretter motta en e-post fra oss.</p>
      <h2 className={classes.formHeader}>E-postadresse</h2>
      <TextInput
        type='text'
        label='E-post'
        value={email}
        id='email'
        onChange={setEmail}
      />
      <Button
        type='primary'
        disabled={!validEmail}
        onClick={async () => {
          await session.forgotPassword(email)
          setEmailSent(true)
        }}>
        Send
            </Button>
    </>
  )
}

export default injectSheet(styles)(ForgotPassword)
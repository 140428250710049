import React from 'react'
import SessionContext from './SessionContext';

const initialState = {
  newUsers: [] as User[],
  fetchingNewUsers: false,
  nextNewUsersFetchAt: 0,
  sendingEmail: undefined as User | undefined,
  getNewUsers: () => { },
  ensureFreshNewUsers: () => { },
  sendSignupEmail: (u: User) => { },
}

const UserContext = React.createContext(initialState)

type State = typeof initialState;

export class UserProvider extends React.Component<{}, State> {
  static contextType = SessionContext
  contextType!: React.ContextType<typeof SessionContext>

  state: State = {
    newUsers: [],
    fetchingNewUsers: false,
    nextNewUsersFetchAt: 0,
    sendingEmail: undefined,
    getNewUsers: this.getNewUsers.bind(this),
    ensureFreshNewUsers: this.ensureFreshNewUsers.bind(this),
    sendSignupEmail: this.sendSignupEmail.bind(this),
  }

  constructor(props: {}) {
    super(props)
  }


  async getNewUsers() {
    await this.setState({
      fetchingNewUsers: true,
      nextNewUsersFetchAt: Date.now() + 10000,
    })
    const newUsers = await this.context.req('user/newUsers')
    this.setState({
      newUsers,
      fetchingNewUsers: false
    })
  }

  async sendSignupEmail(user: User) {
    try {
      await this.setState({ sendingEmail: user })
      await this.context.req('user/sendSignupEmail', { email: user.email })
      this.setState({ sendingEmail: undefined })
    }
    catch (e) {
      console.error(e)
    }
  }

  async ensureFreshNewUsers() {
    if (Date.now() > this.state.nextNewUsersFetchAt) {
      await this.getNewUsers()
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserContext
import React, { useContext, FunctionComponent, Children } from 'react'
import MediaContext from 'src/contexts/MediaContext';
import injectSheet, { WithSheet } from 'react-jss';
import classNames from 'classnames';

const styles = (theme: Theme) => ({
  desktop: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '1.6rem'
  },
  mobile: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem'
  },
})

const PageLinkGrid: StyledFC<typeof styles> = (props) => {
  const media = useContext(MediaContext)
  const { classes } = props
  const childrenCount = Children.count(props.children)
  const cls = media.screenSize === 'desktop' ? classes.desktop : classes.mobile
  return (
    <div className={cls}>{props.children}</div>
  )
}

export default injectSheet(styles)(PageLinkGrid)

import React, { useContext } from 'react'
import injectSheet, { WithSheet } from 'react-jss'
import classNames from 'classnames'

import MediaContext from 'src/contexts/MediaContext';
import { Link } from 'react-router-dom';
import Icon from './Icon';


const styles = (theme: Theme) => ({
  link: {
    padding: '2rem 2rem',
    borderRadius: '5px',
    border: '1px solid #CDCDCD',
    textAlign: 'center',
    transition: 'box-shadow 200ms ease-in-out',
    display: 'block',
    width: '100%',
    boxShadow: theme.smallShadow,
    color: theme.primaryColor
  },
  text: {
    color: theme.txtColor
  },
  desktop: {
    padding: '4rem 2rem',
    boxShadow: theme.largeShadow,
    '&:hover': {
      boxShadow: theme.largeShadowHover
    }
  }
})

type Props = {
  icon: string,
  text: string,
  url: string
}

const PageLink: StyledPFC<Props, typeof styles> = (props) => {
  const media = useContext(MediaContext)
  const { classes } = props
  const cls = classNames({
    [classes.link]: true,
    [classes.desktop]: media.screenSize === 'desktop'
  })
  const iconSize = media.screenSize === 'desktop' ? 'lg' : 'md'
  return (
    <Link
      className={cls}
      to={props.url}>
      <Icon icon={props.icon} size={iconSize} />
      <p className={classes.text}>{props.text}</p>
    </Link>
  )
}

export default injectSheet(styles)(PageLink)
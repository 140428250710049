import React, { ReactNode } from 'react'
import { withRouter } from 'react-router-dom'
import { match } from 'react-router';
import { History } from 'history';
import { parseJwt } from 'src/utils';
import {
  tokenUrl, forgotPasswordUrl, resetPasswordUrl, baseApiUrl
} from 'src/config';

const storageKey = 'sessionData'

export const invalidLoginError = 'Invalid username and/or password'
export const serverError = 'Received a server error. Please try again.'
export const notBackOfficeError =
  'This user does not have access to the backoffice.'

const initialState = {
  login: (email: string, password: string) => { },
  logout: () => { },
  resetPassword: (password: string, token: string) => { },
  forgotPassword: (email: string) => { },
  req: (url: string, payload: any) => { },
  canUploadProducts: (vendorId: string) => false,
  canRegisterClients: (vendorId: string) => false,
  _id: undefined as string | undefined,
  token: undefined as string | undefined,
  vendorPermissions: [] as VendorPermissions[],
  isSuperAdmin: false,
  email: undefined as string | undefined,
  exp: 0
}

const SessionContext = React.createContext(initialState)

export default SessionContext

type Props = {
  children?: ReactNode
  history: History,
  location: any,
  match: match
}

type State = typeof initialState;

class SessionProviderInternal extends React.Component<Props, State, any> {
  constructor(props: Props) {
    super(props)
    this.state = {
      token: undefined,
      _id: undefined,
      email: undefined,
      vendorPermissions: [],
      isSuperAdmin: false,
      exp: 0,
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      forgotPassword: this.forgotPassword.bind(this),
      resetPassword: this.resetPassword.bind(this),
      req: this.req.bind(this),
      canRegisterClients: this.canRegisterClients.bind(this),
      canUploadProducts: this.canUploadProducts.bind(this),
    }
    const rawSessionData = localStorage.getItem(storageKey)
    if (rawSessionData === null) {
      return
    }
    const session = JSON.parse(rawSessionData)
    if (session.exp < Date.now() / 1000) {
      return
    }
    this.state = Object.assign(this.state, session)
  }

  async login(email: string, password: string) {
    if (tokenUrl === undefined) {
      return
    }
    const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    })
    if (response.status === 500) {
      throw new Error(serverError)
    }
    if (response.status === 401) {
      throw new Error(invalidLoginError)
    }
    const token = await response.json()
    const sessionData = parseJwt(token)
    const { _id, exp, vendorPermissions, isSuperAdmin } = sessionData
    const session = { token, _id, exp, vendorPermissions, isSuperAdmin }
    if (!this.hasBackOfficeAccess(session)) {
      throw new Error(notBackOfficeError)
    }
    localStorage.setItem(storageKey, JSON.stringify(session))
    await this.setState(session)
    if (this.state.isSuperAdmin) {
      this.props.history.push('/admin')
    }
    else {
      this.props.history.push('/vendor')
    }
  }

  hasBackOfficeAccess(session: any) {
    if (session.isSuperAdmin !== undefined && session.isSuperAdmin) {
      return true
    }
    return session.vendorPermissions !== undefined &&
      session.vendorPermissions.length > 0
  }

  async forgotPassword(email: string) {
    if (forgotPasswordUrl === undefined) {
      return
    }
    const response = await fetch(forgotPasswordUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    if (response.status === 500) {
      throw new Error('Server error!')
    }
    if (response.status === 401) {
      throw new Error('Invalid username and/or password')
    }
  }

  async resetPassword(password: string, token: string) {
    if (resetPasswordUrl === undefined) {
      return
    }

    const response = await fetch(resetPasswordUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ password })
    })
    if (response.status === 500) {
      throw new Error(serverError)
    }
    if (response.status === 401) {
      throw new Error(invalidLoginError)
    }
  }

  async logout() {
    localStorage.removeItem(storageKey)
    await this.setState({
      token: undefined,
      email: undefined,
      _id: undefined,
      exp: 0,
      vendorPermissions: [],
      isSuperAdmin: false,
    })
    this.props.history.push('/')
  }

  async upload(
    url: string, formdata: FormData) {
    if (this.state.token === undefined) {
      throw new Error('Not logged in.')
    }
    if (this.state.exp < Date.now() / 1000) {
      throw new Error('Expired token')
    }
    const headers: { [index: string]: string } = {
      'Authorization': 'Bearer ' + this.state.token
    }
    const response = await fetch(baseApiUrl + url, {
      method: 'POST',
      body: formdata,
      headers
    })
    if (response.status === 500) {
      throw new Error('Server error.')
    }
    const json = await response.json()
    if (process.env.NODE_ENV === 'development') {
      console.log(`Api response for upload @ ${url}`, json)
    }
    if (!response.ok) {
      throw new Error(json.error)
    }
    return json
  }

  async req(url: string, payload: any = undefined) {
    if (this.state.token === undefined) {
      throw new Error('Not logged in.')
    }
    if (this.state.exp < Date.now() / 1000) {
      throw new Error('Expired token')
    }
    const headers: { [index: string]: string } = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.state.token
    }
    const endPointUrl = baseApiUrl + url
    let response
    if (payload) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Post req @ ${url}`, payload)
      }
      response = await fetch(endPointUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload)
      })
    }
    else {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Get req @ ${endPointUrl}`)
      }
      response = await fetch(endPointUrl, {
        method: 'GET',
        headers
      })
    }
    if (response.status === 500) {
      throw new Error('Server error.')
    }
    const json = await response.json()
    if (process.env.NODE_ENV === 'development') {
      console.log(`Api response for ${payload ? 'POST' : 'GET'} @ ${url}`, json)
    }
    if (!response.ok) {
      throw new Error(json.error)
    }
    return json
  }

  canUploadProducts(vendorId: string) {
    if (this.state.isSuperAdmin) {
      return true
    }
    const perms = this.state.vendorPermissions.filter(
      v => v.vendor._ref == vendorId
    )[0]
    return perms !== undefined && perms.canUploadProducts
  }

  canRegisterClients(vendorId: string) {
    if (this.state.isSuperAdmin) {
      return true
    }
    const perms = this.state.vendorPermissions.filter(
      v => v.vendor._ref == vendorId
    )[0]
    return perms !== undefined && perms.canRegisterClients
  }

  render() {
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    )
  }
}

export const SessionProvider = withRouter(SessionProviderInternal)


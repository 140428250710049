import React, { useContext } from 'react'
import { Route, Redirect, Link } from "react-router-dom"
import SessionContext from 'src/contexts/SessionContext'
import PageLink from 'src/components/PageLink';
import PageLinkGrid from 'src/components/PageLinkGrid';

function HomeAdmin() {
  const session = useContext(SessionContext)
  if (!session.isSuperAdmin) {
    return <Redirect to='/vendor' />
  }
  return (
    <>
      <PageLinkGrid>
        <PageLink
          icon='email'
          text='Send invitasjons-epost'
          url='/admin/sendSignupEmail'
        />
        <PageLink
          icon='business'
          text='Leverandører'
          url='/vendor'
        />
      </PageLinkGrid>
    </>
  )
}

export default HomeAdmin
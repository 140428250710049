import React from 'react'
import SessionContext from './SessionContext';

const initialState = {
  getVendors: () => { },
  ensureFreshVendorData: () => { },
  startImport: (payload: any) => { },
  registerClient: (payload: any) => Promise.resolve(true) as Promise<true> | Error,
  registeringNewClient: false,
  vendors: [] as Vendor[],
  fetchingVendors: false,
  nextFetchAt: 0
}

const VendorContext = React.createContext(initialState)

type State = typeof initialState;

export class VendorProvider extends React.Component<{}, State> {
  static contextType = SessionContext
  contextType!: React.ContextType<typeof SessionContext>

  state: State = {
    vendors: [],
    fetchingVendors: false,
    registeringNewClient: false,
    nextFetchAt: 0,
    getVendors: this.getVendors.bind(this),
    registerClient: this.registerClient.bind(this),
    ensureFreshVendorData: this.ensureFreshVendorData.bind(this),
    startImport: this.startImport.bind(this)
  }

  async registerClient(payload: any) {
    await this.setState({ registeringNewClient: true })
    try {
      await this.context.req('vendor/registerClient', payload)
      await this.setState({ registeringNewClient: false })
      return true
    }
    catch (e) {
      await this.setState({ registeringNewClient: false })
      return e
    }
  }

  async startImport(payload: any) {
    const { vendorId, excel, images } = payload
    const formdata = new FormData()
    formdata.append('vendorId', vendorId)
    formdata.append('excelFile', excel)
    formdata.append('imagesFile', images)
    return await this.context.upload('vendor/importProducts', formdata)

  }
  async getVendors() {
    await this.setState({
      fetchingVendors: true,
      nextFetchAt: Date.now() + 30000
    })
    try {
      const vendors = await this.context.req('vendor')
      await this.setState({ fetchingVendors: false })
      this.setState({
        vendors,
        fetchingVendors: false,
        nextFetchAt: Date.now() + 3600000
      })
    }
    catch (e) {
      await this.setState({
        fetchingVendors: false, nextFetchAt: Date.now() + 30000
      })
    }
  }

  async ensureFreshVendorData() {
    if (Date.now() > this.state.nextFetchAt) {
      await this.getVendors()
    }
  }
  render() {
    return (
      <VendorContext.Provider value={this.state}>
        {this.props.children}
      </VendorContext.Provider>
    )
  }
}

export default VendorContext